import Vue from "vue"
import Router from "vue-router"
import Meta from "vue-meta"
import store from "@/store/index"
import globals from "@/globals"
import MenuList from "@/utils/MenuList.js"
// Layouts
import LayoutHorizontalSidenav from "@/layout/LayoutHorizontalSidenav"

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
	base: "/",
	mode: "history",
	routes: [
		{
			path: "/",
			component: LayoutHorizontalSidenav,
			children: [
				{
					name: "home",
					path: "",
					component: () => import("@/components/Home"),
				},
				{
					name: "home-edit",
					path: "home/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "home" },
				},
				// about us
				{
					name: "our-mission",
					path: "our-mission",
					component: () => import("@/components/AboutUs/About/OurMission/OurMission"),
				},
				{
					name: "our-mission-edit",
					path: "our-mission/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "our_mission" },
				},
				{
					name: "history",
					path: "history",
					component: () => import("@/components/AboutUs/About/History/OurMission"),
				},
				{
					name: "history-edit",
					path: "history/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "history" },
				},
				// {
				//   name: "collegiality-statement",
				//   path: "collegiality-statement",
				//   component: () =>
				//     import(
				//       "@/components/AboutUs/About/CollegialityStatement/CollegialityStatement"
				//     ),
				// },
				// {
				//   name: "collegiality-statement-edit",
				//   path: "collegiality-statement/cms/edit/:id",
				//   component: () =>
				//     import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
				//   props: { contentType: "collegiality_statement" },
				// },
				// {
				//   name: "diversity",
				//   path: "diversity",
				//   component: () =>
				//     import("@/components/AboutUs/Inclusivity/Diversity/OurMission"),
				// },
				// {
				//   name: "diversity-edit",
				//   path: "diversity/cms/edit/:id",
				//   component: () =>
				//     import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
				//   props: { contentType: "diversity" },
				// },
				{
					name: "member-directory",
					path: "member-directory",
					component: () => import("@/components/AboutUs/Directories/MemberDirectory/MemberDirectory"),
				},
				{
					name: "staff-directory",
					path: "staff-directory",
					component: () => import("@/components/AboutUs/Directories/StaffDirectory/StaffDirectory"),
				},
				//governance-committee
				{
					name: "governance-committees",
					path: "governance-committees",
					component: () => import("@/components/AboutUs/GovernanceCommittee/Committee/FeaturedSection"),
				},
				{
					name: "governance-committee-detail",
					path: "governance-committee/:id",
					component: () => import("@/components/AboutUs/GovernanceCommittee/CommitteeDetail/FeaturedSection"),
				},
				{
					name: "scientific-advisory-committee-detail",
					path: "scientific-advisory-committee",
					component: () =>
						import("@/components/AboutUs/GovernanceCommittee/CommitteeDetail/ScientificAdvisoryFeaturedSection"),
				},
				{
					name: "board-of-trustees-committee-detail",
					path: "board-of-trustees",
					component: () =>
						import("@/components/AboutUs/GovernanceCommittee/CommitteeDetail/BoardOfTrusteesFeaturedSection"),
				},
				{
					name: "human-resources-advisory-committee-detail",
					path: "human-resources-advisory-committee",
					component: () =>
						import("@/components/AboutUs/GovernanceCommittee/CommitteeDetail/HumanResourcesAdvisoryFeaturedSection"),
				},
				{
					name: "emeriti-trustees",
					path: "emeriti-trustees",
					component: () =>
						import("@/components/AboutUs/GovernanceCommittee/CommitteeDetail/EmeritiTrusteesFeaturedSection"),
				},
				{
					name: "governance-committee-edit",
					path: "governance-committees/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "governance_committees" },
				},
				{
					name: "board-of-trustees-edit",
					path: "board-of-trustees/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "board_of_trustees_committee_detail" },
				},
				{
					name: "human-resources-advisory-committee-edit",
					path: "human-resources-advisory-committee/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "human_resources_advisory_committee_detail" },
				},
				{
					name: "scientific-advisory-committee-edit",
					path: "scientific-advisory-committee/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "scientific_advisory_committee_detail" },
				},
				{
					path: "people",
					name: "people-list",
					component: () => import("@/components/AboutUs/Directories/MemberDirectory/PeopleList"),
				},
				{
					path: "people/:id",
					name: "people",
					component: () => import("@/components/AboutUs/Directories/MemberDirectory/PersonalProfile"),
				},
				{
					path: "people/:id/edit",
					name: "people-edit",
					component: () => import("@/components/AboutUs/Directories/MemberDirectory/PersonalEdit"),
				},
				//semester programs
				{
					name: "programs",
					path: "programs",
					component: () =>
						import("@/components/ScientificActivity/SemesterProgram/Program/CurrentAndUpcomingSemesterProgram"),
					props: { programType: "programs" },
				},
				{
					name: "programs-details",
					path: "programs/:id",
					component: () =>
						import("@/components/ScientificActivity/SemesterProgram/ProgramDetail/ProgramDetailFeaturedSection"),
					props: { programType: "programs" },
				},
				{
					name: "past-semester-programs",
					path: "past/programs",
					component: () => import("@/components/ScientificActivity/SemesterProgram/Program/PastSemesterProgram"),
					props: { programType: "past" },
				},
				{
					name: "past-programs-details",
					path: "past/programs/:id",
					component: () =>
						import("@/components/ScientificActivity/SemesterProgram/ProgramDetail/ProgramDetailFeaturedSection"),
					props: { programType: "past" },
				},
				{
					name: "programs-edit",
					path: "programs/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "programs" },
				},
				{
					name: "past-semester-programs-edit",
					path: "past/programs/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "past_semester_programs" },
				},
				{
					name: "program-schedules",
					path: "programs/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "programs" },
				},
				//workshops
				{
					name: "workshops",
					path: "workshops",
					component: () => import("@/components/ScientificActivity/Workshop/AllWorkshop/CurrentAndUpcomingWorkshop"),
					props: { workshopType: "workshops" },
				},
				{
					name: "workshop-details",
					path: "workshops/:id",
					component: () =>
						import("@/components/ScientificActivity/Workshop/WorkshopDetail/WorkshopDetailFeaturedSection"),
					props: { workshopType: "workshops" },
				},
				{
					name: "past-workshops",
					path: "past/workshops",
					component: () => import("@/components/ScientificActivity/Workshop/AllWorkshop/PastWorkshop"),
					props: { workshopType: "past" },
				},
				{
					name: "past-workshop-details",
					path: "past/workshops/:id",
					component: () =>
						import("@/components/ScientificActivity/Workshop/WorkshopDetail/WorkshopDetailFeaturedSection"),
					props: { workshopType: "past" },
				},
				{
					path: "workshop/:id/registration",
					name: "workshop-registration",
					component: () =>
						import("@/components/ScientificActivity/Workshop/WorkshopDetail/WorkshopRegistrationFeaturedSection"),
				},
				{
					name: "workshops-edit",
					path: "workshops/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "workshops" },
				},
				{
					name: "past-workshops-edit",
					path: "past/workshops/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "past_workshops" },
				},
				{
					name: "workshop-schedules",
					path: "workshops/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "workshops" },
				},
				// Public Events
				{
					name: "public-event",
					path: "public-events/:id",
					component: () => import("@/components/ScientificActivity/PublicEvent/PublicEventDetailFeaturedSection"),
				},
				//Colloquia Seminars
				{
					name: "seminars",
					path: "seminars",
					component: () =>
						import(
							"@/components/ScientificActivity/ColloquiaAndSeminar/ColloquiaSeminar/CurrentAndUpcomingColloquiaSeminar"
						),
					props: { colloquiaSeminarsType: "seminars" },
				},
				{
					name: "colloquia-seminars-details",
					path: "seminars/:id",
					component: () =>
						import(
							"@/components/ScientificActivity/ColloquiaAndSeminar/ColloquiaAndSeminarDetail/ColloquiaAndSeminarDetailFeaturedSection"
						),
					props: { colloquiaSeminarsType: "seminars" },
				},
				{
					name: "past-colloquia-seminars",
					path: "past/seminars",
					component: () =>
						import("@/components/ScientificActivity/ColloquiaAndSeminar/ColloquiaSeminar/PastColloquiaSeminar"),
					props: { colloquiaSeminarsType: "past" },
				},
				{
					name: "past-colloquia-seminars-details",
					path: "past/seminars/:id",
					component: () =>
						import(
							"@/components/ScientificActivity/ColloquiaAndSeminar/ColloquiaAndSeminarDetail/ColloquiaAndSeminarDetailFeaturedSection"
						),
					props: { colloquiaSeminarsType: "past" },
				},
				{
					name: "colloquia-seminars-edit",
					path: "seminars/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "seminars" },
				},
				{
					name: "past-colloquia-seminars-edit",
					path: "past/seminars/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "past_colloquia_seminars" },
				},
				{
					name: "colloquia-seminars-schedules",
					path: "seminars/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "seminars" },
				},
				{
					path: "seminar/:id/registration",
					name: "seminar-registration",
					component: () =>
						import(
							"@/components/ScientificActivity/ColloquiaAndSeminar/ColloquiaAndSeminarDetail/SeminarRegistrationFeaturedSection.vue"
						),
				},
				//adjoint
				{
					name: "adjoint",
					path: "adjoint",
					component: () => import("@/components/ScientificActivity/SummerProgram/Adjoint/AdjointFeaturedSection"),
				},
				{
					path: "adjoint/:id",
					name: "adjoint-program-details",
					component: () =>
						import("@/components/ScientificActivity/SummerProgram/AdjointProgramDetail/AdjointDetailFeaturedSection"),
				},
				{
					name: "adjoint-edit",
					path: "adjoint/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "adjoint" },
				},
				{
					name: "adjoint-schedules",
					path: "adjoint/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "adjoints" },
				},
				//msri-up
				{
					name: "msri-up",
					path: "msri-up",
					component: () => import("@/components/ScientificActivity/SummerProgram/MSRI_UP_UG/MSRIUpUgFeaturedSection"),
				},
				{
					name: "msri-up-program-details",
					path: "msri-up/:id",
					component: () =>
						import("@/components/ScientificActivity/SummerProgram/MSRI_UP_ProgramDetail/MSRI_UP_DetailFeaturedSection"),
				},
				{
					name: "msri-up-edit",
					path: "msri-up/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "msri_up" },
				},
				{
					name: "msri-up-schedules",
					path: "msri-up/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "msri_ups" },
				},
				//summer-graduate-schools
				{
					name: "summer-graduate-schools",
					path: "summer-schools",
					component: () =>
						import(
							"@/components/ScientificActivity/SummerProgram/SummerGraduateSchool/SummerGraduateSchoolFeaturedSection"
						),
				},
				{
					name: "summer-graduate-schools-details",
					path: "summer-schools/:id",
					component: () =>
						import(
							"@/components/ScientificActivity/SummerProgram/SummerGraduateSchoolDetail/SummerGraduateSchoolDetailFeaturedSection"
						),
				},
				{
					name: "summer-graduate-schools-edit",
					path: "summer-schools/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "summer_graduate_schools" },
				},
				{
					name: "summer-graduate-schools-schedules",
					path: "summer-schools/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "summer_schools" },
				},
				//summer-research-in-mathematics
				{
					name: "summer-research-in-mathematics",
					path: "summer-research",
					component: () =>
						import(
							"@/components/ScientificActivity/SummerProgram/SummerResearchInMathematics/SummerResearchInMathematicsFeaturedSection"
						),
				},
				{
					name: "summer-research-in-mathematics-details",
					path: "summer-research/:id",
					component: () =>
						import(
							"@/components/ScientificActivity/SummerProgram/SummerResearchInMathematicsDetail/SummerResearchInMathematicsDetailFeaturedSection"
						),
				},
				{
					name: "summer-research-in-mathematics-edit",
					path: "summer-research/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "summer_research_in_mathematics" },
				},
				//May-Up
				{
					name: "may-up",
					path: "may-up",
					component: () => import("@/components/ScientificActivity/SummerProgram/MayUp/MayUpFeaturedSection"),
				},
				{
					path: "may-up/:id",
					name: "may-up-program-details",
					component: () =>
						import("@/components/ScientificActivity/SummerProgram/MayUpProgramDetail/MayUpDetailFeaturedSection"),
				},
				{
					name: "may-up-edit",
					path: "may-up/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "may_up" },
				},
				{
					name: "may-up-schedules",
					path: "may-up/:id/schedules/:schedule_id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleAndNotesDetail"),
					props: { scheduleApi: "may_ups" },
				},
				// scientific-activities
				{
					path: "scientific-activities",
					name: "scientific-activities",
					component: () =>
						import("@/components/ScientificActivity/ScientificActivityHome/ScientificActivityFeaturedSection"),
				},
				// news and events
				{
					name: "news-and-events",
					path: "news-and-events",
					component: () => import("@/components/NewsAndEvent/NewsAndEventHome/NewsAndEventFeaturedSection"),
				},
				{
					name: "news-and-events-edit",
					path: "news-and-events/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "news_and_events" },
				},
				// MSRI in the media
				{
					name: "media",
					path: "media",
					component: () => import("@/components/NewsAndEvent/PressAndMedia/MSRIMedia/MediaFeaturedSection"),
				},
				{
					name: "media-create",
					path: "media/create",
					component: () => import("@/components/NewsAndEvent/PressAndMedia/MSRIMedia/CreateMediaSection"),
				},
				{
					name: "media-edit",
					path: "media/:id/edit",
					props: true,
					component: () => import("@/components/NewsAndEvent/PressAndMedia/MSRIMedia/EditMediaSection"),
				},
				// Press Release Archive
				{
					name: "press-release-archive",
					path: "press-release-archive",
					component: () =>
						import("@/components/NewsAndEvent/PressAndMedia/PressReleaseArchive/PressReleaseArchiveFeaturedSection"),
				},
				{
					name: "press-release-archive-create",
					path: "press-release-archive/create",
					component: () =>
						import("@/components/NewsAndEvent/PressAndMedia/PressReleaseArchive/PressReleaseArchiveFormSection"),
				},
				{
					name: "press-release-archive-edit",
					path: "press-release-archive/:id/edit",
					props: true,
					component: () =>
						import("@/components/NewsAndEvent/PressAndMedia/PressReleaseArchive/PressReleaseArchiveFormSection"),
				},
				// Emissary News
				{
					name: "newsletter-archive",
					path: "newsletter-archive",
					component: () =>
						import("@/components/NewsAndEvent/PressAndMedia/EmissaryNewsPage/EmissaryNewsFeaturedSection"),
				},
				{
					name: "newsletter-archive-create",
					path: "newsletter-archive/create",
					component: () => import("@/components/NewsAndEvent/PressAndMedia/EmissaryNewsPage/CreateEmissaryNewsSection"),
				},

				//Public Events Outreach
				// {
				//   name: "public-understanding-of-math",
				//   path: "public-understanding-of-math",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/PublicEventsAndOutreach/PublicEventsOutreach/CurrentAndUpcomingPublicEvent"
				//     ),
				//   props: { eventType: "current-and-upcoming" }
				// },
				// {
				//   name: "past-public-understanding-of-math",
				//   path: "past/public-understanding-of-math",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/PublicEventsAndOutreach/PublicEventsOutreach/PastPublicEvent"
				//     ),
				//   props: { eventType: "past" }
				// },
				// {
				//   name: "public-understanding-of-math-create",
				//   path: "public-understanding-of-math/create",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/PublicEventsAndOutreach/PublicEventsOutreach/PublicEventFormSection"
				//     ),
				// },
				// {
				//   name: "public-understanding-of-math-edit",
				//   path: "public-understanding-of-math/cms/edit/:id",
				//   props: true,
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/PublicEventsAndOutreach/PublicEventsOutreach/PublicEventFormSection"
				//     ),
				// },

				// National Math Festival
				// {
				//   name: "national-math-festival",
				//   path: "national-math-festival",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/PublicEventsAndOutreach/NationalMathFestival/NationalMathFestivalFeaturedSection"
				//     ),
				// },
				// {
				//   name: "national-math-festival-edit",
				//   path: "national-math-festival/cms/edit/:id",
				//   component: () =>
				//     import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
				//   props: { contentType: "national_math_festival" },
				// },

				//MSRI Publications
				// {
				//   name: "publications",
				//   path: "publications",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/FilmAndPublication/MSRIPublication/PublicationFeaturedSection"
				//     ),
				// },
				// {
				//   name: "publications-create",
				//   path: "publications/create",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/FilmAndPublication/MSRIPublication/PublicationFormSection"
				//     ),
				// },
				// {
				//   name: "publications-edit",
				//   path: "publications/cms/edit/:id",
				//   props: true,
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/FilmAndPublication/MSRIPublication/PublicationFormSection"
				//     ),
				// },
				// Documentaries Public Films
				// {
				//   name: "documentaries-and-public-films",
				//   path: "documentaries-and-public-films",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/FilmAndPublication/DocumentarieAndPublicFilm/DocumentariePublicFilmFeaturedSection"
				//     ),
				// },
				// {
				//   name: "documentaries-and-public-films-create",
				//   path: "documentaries-and-public-films-create/create",
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/FilmAndPublication/DocumentarieAndPublicFilm/DocumentariePublicFilmFormSection"
				//     ),
				// },
				// {
				//   name: "documentaries-and-public-films-edit",
				//   path: "documentaries-and-public-films-edit/:id/cms/edit",
				//   props: true,
				//   component: () =>
				//     import(
				//       "@/components/NewsAndEvent/FilmAndPublication/DocumentarieAndPublicFilm/DocumentariePublicFilmFormSection"
				//     ),
				// },

				// Plan Your Visit
				// Travel and Visas
				{
					name: "travel-policies-procedures",
					path: "travel-policies-procedures",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Visa Information
				{
					name: "visa-information",
					path: "visa-information",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Family Services
				{
					name: "family-services",
					path: "family-services",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Housing Information
				// {
				//   name: "housing-information",
				//   path: "housing-information",
				//   component: () =>
				//     import(
				//       "@/components/PlanYourVisit/HousingInformation/HousingInformationFeaturedSection"
				//     ),
				// },
				// {
				//   name: "short-term-housing",
				//   path: "housing-information/short-term-housing",
				//   component: () =>
				//     import(
				//       "@/components/PlanYourVisit/PlanYourVisitDynamicPage"
				//     ),
				// },
				// {
				//   name: "long-term-housing",
				//   path: "housing-information/long-term-housing",
				//   component: () =>
				//     import(
				//       "@/components/PlanYourVisit/PlanYourVisitDynamicPage"
				//     ),
				// },
				// Directions to MSRI
				{
					name: "directions",
					path: "directions",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Parking
				{
					name: "parking",
					path: "parking",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Public Transportation
				{
					name: "public-transportation",
					path: "public-transportation",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Shuttle Bus Schedule
				{
					name: "shuttle-bus-schedule",
					path: "shuttle-bus-schedule",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// All Resources
				{
					name: "all-resources",
					path: "all-resources",
					component: () => import("@/components/PlanYourVisit/MemberResource/AllResource/AllResourceFeaturedSection"),
				},
				{
					name: "all-resources-edit",
					path: "all-resources/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "all_resources" },
				},
				// Healthcare
				{
					name: "healthcare",
					path: "healthcare",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Computing handbook
				{
					name: "computing-handbook",
					path: "computing-handbook",
					component: () => import("@/components/PlanYourVisit/PlanYourVisitDynamicPage"),
				},
				// Order Lunch
				{
					name: "order-lunch",
					path: "order-lunch",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchFeaturedSection"),
				},
				{
					name: "order-lunch-new",
					path: "order-lunch/new",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchNewFormSection"),
				},
				{
					name: "order-lunch-edit",
					path: "order-lunch/edit/:id",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchNewFormSection"),
				},
				{
					name: "lunch-order-scheduling",
					path: "order-lunch/lunch-order-scheduling",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchSchedulingSection"),
				},
				{
					name: "lunch-order-cart",
					path: "order-lunch/:id/new",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchCartSection"),
				},
				{
					name: "cart-checkout",
					path: "cart/checkout/:id",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchCartCheckoutSection"),
				},
				{
					name: "payment-checkout",
					path: "payment/checkout/",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchCartPreviewSection"),
				},
				{
					name: "order-line-items",
					path: "order-line-items/:id",
					component: () => import("@/components/PlanYourVisit/MemberResource/OrderLunch/OrderLunchItemListSection"),
				},
				// Support MSRI
				{
					name: "support",
					path: "support",
					component: () => import("@/components/SupportMsri/SupportFeaturedSection"),
				},
				{
					name: "support-edit",
					path: "support/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "support" },
				},
				// Goverment Sponsors
				{
					name: "academic-sponsors",
					path: "academic-sponsors",
					component: () =>
						import("@/components/SupportMsri/Sponsors/GovermentSponsors/GovermentSponsorsFeaturedSection"),
				},
				{
					name: "academic-sponsors-edit",
					path: "academic-sponsors/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "academic_sponsors" },
				},
				// Private Sponsors
				{
					name: "private-sponsors",
					path: "private-sponsors",
					component: () => import("@/components/SupportMsri/Sponsors/PrivateSponsors/PrivateSponsorsFeaturedSection"),
				},
				{
					name: "private-sponsors-edit",
					path: "private-sponsors/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "private_sponsors" },
				},
				// Public Sponsors
				{
					name: "public-sponsors",
					path: "public-sponsors",
					component: () => import("@/components/SupportMsri/Sponsors/PublicSponsors/PublicSponsorsFeaturedSection"),
				},
				{
					name: "public-sponsors-edit",
					path: "public-sponsors/cms/edit/:id",
					component: () => import("@/components/Dynamic/ScientificActivitiesContentEdit"),
					props: { contentType: "public_sponsors" },
				},
				// Current Donors
				// {
				//   name: "become-a-donors",
				//   path: "become-a-donors",
				//   component: () => import("@/components/SupportMsri/DonorRecognition/CurrentDonors/CurrentDonorsFeaturedSection"),
				// },
				// {
				//   name: "become-a-donors-edit",
				//   path: "become-a-donors/cms/edit/:id",
				//   component: () =>
				//     import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
				//   props: { contentType: "become_a_donors" },
				// },
				// Museion Society
				// {
				//   name: "museion-society",
				//   path: "museion-society",
				//   component: () => import("@/components/SupportMsri/DonorRecognition/MuseionSociety/MuseionSocietyFeaturedSection"),
				// },
				// {
				//   name: "museion-society-edit",
				//   path: "museion-society/cms/edit/:id",
				//   component: () =>
				//     import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
				//   props: { contentType: "museion_society" },
				// },
				// Calendar
				{
					name: "calendar-all-events",
					path: "calendar/all-events",
					component: () => import("@/components/Calendar/CalendarAllEvent/CalendarAllEventFeaturedSection"),
				},
				// Video
				{
					name: "video-analytics",
					path: "video-analytics",
					component: () => import("@/components/Video/VideoAnalytics"),
				},
				{
					name: "video",
					path: "video",
					component: () => import("@/components/Video/DashboardVideo/DashboardVideoFeaturedSection"),
				},
				{
					name: "video-edit",
					path: "video/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "video" },
				},
				{
					name: "vimeo-edit",
					path: "video/:id/vimeo-edit",
					component: () => import("@/components/CommonPages/EditVimeoVideo"),
				},
				{
					name: "video-detail",
					path: "video-details/:id/:scheduleId",
					component: () => import("@/components/Video/DashboardVideo/VideoDetailSection"),
				},
				{
					name: "video-all",
					path: "video/all/:type",
					component: () => import("@/components/Video/DashboardVideo/AllVideoSection"),
				},
				{
					name: "video-subject",
					path: "video/subject/:tagId",
					component: () => import("@/components/Video/DashboardVideo/VideoSubjectFilterSection"),
				},
				{
					name: "video-semester",
					path: "video/semester/:from/:to",
					component: () => import("@/components/Video/DashboardVideo/VideoSemesterFilterSection"),
				},
				// Schedule Form Section
				{
					name: "schedule-create-video",
					path: "schedule-create-video/:id",
					component: () => import("@/components/CommonPages/ScheduleForm/CreateScheduleVideoSection"),
				},
				{
					name: "schedule-edit-video",
					path: "schedule-edit-video/:videoId",
					component: () => import("@/components/CommonPages/ScheduleForm/CreateScheduleVideoSection"),
				},
				{
					name: "schedule-create-supplement",
					path: "schedule-create-supplement/:id",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleSupplementFormSection.vue"),
				},
				{
					name: "schedule-edit-supplement",
					path: "schedule-edit-supplement/:supplementId",
					component: () => import("@/components/CommonPages/ScheduleForm/ScheduleSupplementFormSection.vue"),
				},
				// Exit Survey Form
				{
					name: "exit-survey-programs",
					path: "exit-survey/programs/:id",
					component: () =>
						import("@/components/ScientificActivity/SemesterProgram/ProgramDetail/ProgramExitSurveySection.vue"),
					props: {
						type: "programs",
						typeName: "Program",
						typePath: "programs",
					},
				},
				{
					name: "exit-survey-adjoints",
					path: "exit-survey/adjoint/:id",
					component: () =>
						import("@/components/ScientificActivity/SemesterProgram/ProgramDetail/ProgramExitSurveySection.vue"),
					props: { type: "adjoints", typeName: "Adjoint", typePath: "adjoint" },
				},
				{
					name: "exit-survey-summer-research-woman",
					path: "exit-survey/summer-research/:id",
					component: () =>
						import("@/components/ScientificActivity/SemesterProgram/ProgramDetail/ProgramExitSurveySection.vue"),
					props: {
						type: "summer_research_woman",
						typeName: "Summer Research In Mathematics",
						typePath: "summer-research-in-mathematics",
					},
				},
				{
					name: "exit-survey-question",
					path: "exit-survey-question/:event_type/:step_name/edit",
					component: () => import("@/components/CommonPages/ExitSurveyFormWizard/ExitSurveyQuestionForm.vue"),
				},
				//Program Proposal
				{
					name: "pending-program-proposals-representatives",
					path: "pending/program_proposals/representatives",
					component: () =>
						import(
							"@/components/ScientificActivity/SemesterProgram/ProgramProposal/ProgramPendingProposalFeaturedSection.vue"
						),
				},
				{
					name: "approved-program-proposals-representatives",
					path: "approved/program_proposals/representatives",
					component: () =>
						import(
							"@/components/ScientificActivity/SemesterProgram/ProgramProposal/ProgramApprovedProposalFeaturedSection.vue"
						),
				},
				// Adjoint Admin
				{
					name: "adjoint-admin",
					path: "adjoint-admin",
					component: () => import("@/components/AdjointAdmin/AdjointAdminFeaturedSection"),
				},
				{
					path: "participants-preview",
					name: "participants-preview",
					component: () => import("@/components/AdjointAdmin/Participant/ParticipantsPreviewSection"),
				},
				{
					path: "participants-report",
					name: "participants-report",
					component: () => import("@/components/AdjointAdmin/Participant/ViewParticipantsReportFeaturedSection"),
				},
				{
					name: "adjoint-participant-survey",
					path: "adjoint-participant-survey/:id/:personId",
					component: () =>
						import(
							"@/components/AdjointAdmin/Participant/AdjointParticipantsSurvey/AdjointParticipantsSurveyFeaturedSection"
						),
				},
				{
					name: "cohort-participant-report",
					path: "cohort-participant-report/:cohortYear/:cohortId",
					component: () => import("@/components/AdjointAdmin/Cohort/CohortParticipantReport"),
				},
				{
					name: "research-leader-report",
					path: "research-leader-report/:id",
					component: () => import("@/components/AdjointAdmin/ResearchLeader/ResearchLeaderReport"),
				},
				{
					path: "adjoint-info",
					name: "adjoint-info",
					component: () => import("@/components/AdjointAdmin/AdjointEmail/AdjointEmailFeaturedSection"),
				},
				{
					path: "adjoint-info-edit",
					name: "adjoint-info-edit",
					props: true,
					component: () => import("@/components/AdjointAdmin/AdjointEmail/AdjointEmailUpdateSection"),
				},
				// Dynamic pages
				// {
				//   name: "custom-pages",
				//   path: "/cms/:menu",
				//   component: () => import("@/components/Dynamic/CustomDynamicPage"),
				// },
				// Page Builder
				{
					name: "page-builder",
					path: "page-builder",
					component: () => import("@/pages/CreateMenu"),
				},
				{
					name: "page-builder-edit",
					path: ":path/page-builder-edit/:id",
					component: () => import("@/components/CreateMenu/SubMenuPageEdit"),
				},
				{
					name: "page-content-edit",
					path: ":path/cms/edit/:id",
					component: () => import("@/components/Dynamic/PageContentEditPage"),
				},
				{
					name: "page-revision",
					path: ":path/revision/:id",
					component: () => import("@/components/CreateMenu/RevisionContentPage"),
				},
				//Quick link Builder
				{
					name: "quick-link-builder",
					path: "quick-link-builder",
					component: () => import("@/components/QuickLink/QuickLinkFeaturedSection"),
				},
				//Message Banner
				{
					name: "message-banner",
					path: "message-banner",
					component: () => import("@/components/MessageBanner/MessageBannerFeaturedSection"),
				},
				//CK Editor Image Upload
				{
					name: "ck-editor-image-upload",
					path: "ck-editor-image-upload",
					component: () => import("@/components/CKEditorImageUpload/CKEditorImageUploadFeaturedSection"),
				},
				//CK Editor File Upload
				{
					name: "ck-editor-file-upload",
					path: "ck-editor-file-upload",
					component: () => import("@/components/CKEditorImageUpload/CKEditorFileUploadFeaturedSection"),
				},
				// Dashboad
				// {
				//   name: "whitelist-emails",
				//   path: "whitelist-emails",
				//   component: () => import("@/components/Dashboard/WhitelistEmail/WhitelistEmailsFeatureSection"),
				// },
				{
					name: "url-mapper",
					path: "url-mapper",
					component: () => import("@/components/Dashboard/URLMapper/UrlMapperFeatureSection"),
				},
				{
					name: "folder-image-logs",
					path: "folder-image-logs",
					component: () => import("@/components/Dashboard/Folder&ImageLogs/FolderImageLogsFeatureSection"),
				},
				{
					name: "user-list",
					path: "user-list",
					component: () => import("@/components/Dashboard/UserListFeaturedSection"),
				},
				{
					name: "user-spam-list",
					path: "user-spam-list",
					component: () => import("@/components/Dashboard/UserSpamListFeaturedSection"),
				},
				{
					name: "exit-survey",
					path: "exit-survey",
					component: () => import("@/components/Dashboard/ExitSurvey/ExitSurveyFeaturedSection.vue"),
				},
				{
					name: "staff-dashboard",
					path: "staff-dashboard",
					component: () => import("@/components/Dashboard/StaffDashboard"),
				},
				{
					name: "gauth",
					path: "gauth",
					component: () => import("@/pages/ThirdPartySignin"),
				},
				{
					name: "signout",
					path: "signout",
					component: () => import("@/pages/SignOut"),
				},
				{
					name: "signin",
					path: "signin",
					component: () => import("@/pages/Signin"),
				},
				{
					name: "signup",
					path: "signup",
					component: () => import("@/pages/Signup"),
				},
				{
					name: "forgot-password",
					path: "forgot-password",
					component: () => import("@/pages/ForgotPassword"),
				},
				{
					name: "reset-password",
					path: "reset-password",
					component: () => import("@/pages/ResetPassword"),
				},
				{
					name: "email-confirmation",
					path: "email-confirmation",
					component: () => import("@/pages/EmailConfirmation"),
				},
				{
					name: "donate",
					path: "donate",
					component: () => import("@/components/Dashboard/DonateFeaturedSection"),
				},
				{
					name: "donate-edit",
					path: "donate/cms/edit/:id",
					component: () => import("@/components/AboutUs/About/OurMission/AboutUsEdit"),
					props: { contentType: "donate" },
				},
				{
					name: "search",
					path: "search",
					component: () => import("@/components/Search"),
				},
				// Ck editor Image Preview
				{
					path: "/ckeditor/image",
					name: "ckeditor-image",
					component: () => import("@/components/CommonPages/CkEditorImagePreview"),
					meta: {
						hideNavBar: true,
					},
				},
				// Ck editor File Preview
				{
					path: "/ckeditor/file",
					name: "ckeditor-file",
					component: () => import("@/components/CommonPages/CkEditorFilePreview"),
					meta: {
						hideNavBar: true,
					},
				},
				{
					path: "restaurants/new",
					name: "restaurant-add",
					component: () => import("@/components/Restaurants/RestaurantForm"),
				},
				{
					path: "restaurants/:id/edit",
					name: "restaurant-edit",
					component: () => import("@/components/Restaurants/RestaurantForm"),
				},
				{
					path: "restaurants",
					name: "restaurants",
					component: () => import("@/components/Restaurants/RestaurantListFeaturedSection"),
				},
				{
					path: "restaurants/:id",
					name: "restaurant-detail",
					component: () => import("@/components/Restaurants/RestaurantDetail"),
				},
				{
					path: "restaurants/:id/full_menu",
					name: "restaurant-full-menu",
					component: () => import("@/components/Restaurants/RestaurantFullMenu"),
				},
				{
					path: "restaurants/:id/menus/new",
					name: "menu-add",
					component: () => import("@/components/Restaurants/Category/RestaurantCategoryForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/edit",
					name: "menu-edit",
					component: () => import("@/components/Restaurants/Category/RestaurantCategoryForm"),
				},
				{
					path: "restaurants/:id/menus",
					name: "menu-list",
					component: () => import("@/components/Restaurants/Category/RestaurantCategoryList"),
				},
				{
					path: "restaurants/:id/menus/:menuId",
					name: "menu-detail",
					component: () => import("@/components/Restaurants/Category/RestaurantCategoryDetail"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/new",
					name: "menu-item-add",
					component: () => import("@/components/Restaurants/MenuItem/RestaurantMenuItemForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/edit",
					name: "menu-item-edit",
					component: () => import("@/components/Restaurants/MenuItem/RestaurantMenuItemForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items",
					name: "menu-item-list",
					component: () => import("@/components/Restaurants/MenuItem/RestaurantMenuItemList"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId",
					name: "menu-item-detail",
					component: () => import("@/components/Restaurants/MenuItem/RestaurantMenuItemDetail"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/options/new",
					name: "menu-item-option-add",
					component: () => import("@/components/Restaurants/MenuItemOption/RestaurantMenuItemOptionForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/options/:optionId/edit",
					name: "menu-item-option-edit",
					component: () => import("@/components/Restaurants/MenuItemOption/RestaurantMenuItemOptionForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/options",
					name: "menu-item-option-list",
					component: () => import("@/components/Restaurants/MenuItemOption/RestaurantMenuItemOptionList"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/options/:optionId",
					name: "menu-item-option-detail",
					component: () => import("@/components/Restaurants/MenuItemOption/RestaurantMenuItemOptionDetail"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/toppings/new",
					name: "menu-item-topping-add",
					component: () => import("@/components/Restaurants/MenuItem/RestaurantMenuItemToppingForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/toppings/:toppingId/edit",
					name: "menu-item-topping-edit",
					component: () => import("@/components/Restaurants/MenuItem/RestaurantMenuItemToppingForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/options/:optionId/choices/new",
					name: "menu-item-option-choice-add",
					component: () => import("@/components/Restaurants/MenuItemOption/RestaurantMenuOptionChoiceForm"),
				},
				{
					path: "restaurants/:id/menus/:menuId/items/:itemId/options/:optionId/choices/:choiceId/edit",
					name: "menu-item-option-choice-edit",
					component: () => import("@/components/Restaurants/MenuItemOption/RestaurantMenuOptionChoiceForm"),
				},
				{
					path: "general_events/:id/agenda",
					name: "general-events-agenda",
					component: () => import("@/components/GeneralEvent/Agenda/AgendaView"),
				},
				{
					path: "general_events/:id/agenda/new",
					name: "general-events-agenda-create",
					component: () => import("@/components/GeneralEvent/Agenda/AgendaForm"),
				},
				{
					path: "general_events/:id/agenda/edit",
					name: "general-events-agenda-edit",
					component: () => import("@/components/GeneralEvent/Agenda/AgendaForm"),
				},
				{
					path: "x11_forwarding",
					name: "x11-forwarding",
					component: () => import("@/components/X11Forwarding")
				},
				// put this in last always to work custom dynamic pages
				{
					name: "custom-pages",
					path: "*",
					component: () => import("@/components/Dynamic/CustomDynamicPage"),
				},
			],
		},
		{
			// Not Authorized page
			path: "*",
			name: "not-authorized",
			component: () => import("@/pages/NotAuthorized"),
		},
		{
			// 404 Not Found page
			path: "*",
			name: "not-found",
			component: () => import("@/pages/404"),
		},
	],
})

router.afterEach(() => {
	// On small screens collapse sidenav
	if (window.layoutHelpers?.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
		setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
	}
	// Scroll to top of the page
	globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
	const authorizedCmsEditPageList = MenuList().authorizedCmsEditPageList
	if (store.getters.isAuthenticated) {
		if (authorizedCmsEditPageList.indexOf(to.name) !== -1 && !store.getters.authUser.can_cms_edit) {
			next({ name: "not-authorized" })
		} else {
			next()
		}
	} else {
		next()
	}
	window.onbeforeunload = () => {
		window.scrollTo(0, 0)
	}
})

// router.afterEach((to, from) => {
//   if (
//     from.path !== "/" &&
//     from.path !== "/signout" &&
//     from.path !== "/signin" &&
//     from.path !== "/gauth" &&
//     from.name !== "not-authorized" &&
//     from.name !== "not-found" &&
//     from.name !== "reset-password" &&
//     from.name !== "email-confirmation"
//   ) {
//     if (to.name !== "not-authorized" && to.name !== "not-found") {
//       localStorage.setItem("LS_ROUTE_KEY", from.path)
//     } else {
//       localStorage.setItem("LS_ROUTE_KEY", "")
//     }
//   }
// })
export default router
